<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img class="logo-light" src="./logo.png" alt="Logo" width="150" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Вітаємо в petrolcard! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Увійдіть у свій обліковий запис і почніть пригоду
          </b-card-text>

          <validation-observer
            ref="codeForm"
            #default="{invalid}"
            v-if="!showLoginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="sendCode"
            >
              <!-- email -->
              <b-form-group
                label="Номер телефону"
                label-for="login-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Номер телефону"
                  vid="text"
                  rules="required"
                >
                  <b-form-input
                    id="login-phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="login-phone"
                    placeholder="380681234567"
                    pattern="^\+?3?8?(0[3-9][0-9]\d{7})$" title="Номер телефону повинен бути у форматі 380681234567"
                    oninvalid="setCustomValidity('Номер телефону повинен бути у форматі 380681234567')"
                    oninput="try{setCustomValidity('')}catch(e){}"
                  />
                  <small class="text-danger" v-if="errorPhone">Неправильний номер телефону</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Надіслати код
              </b-button>
              
            </b-form>
          </validation-observer>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
            v-if="showLoginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="sendLogin"
            >
              <b-form-group
                label="Номер телефону"
                label-for="login-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Номер телефону"
                  vid="text"
                  rules="required"
                >
                  <b-form-input
                    id="login-phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="login-phone"
                    placeholder="380681234567"
                    pattern="^\+?3?8?(0[3-9][0-9]\d{7})$" title="Номер телефону повинен бути у форматі 380681234567"
                    oninvalid="setCustomValidity('Номер телефону повинен бути у форматі 380681234567')"
                    oninput="try{setCustomValidity('')}catch(e){}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Код з смс"
                label-for="login-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Код з смс"
                  vid="text"
                  rules="required"
                >
                  <b-form-input
                    id="login-code"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    name="login-code"
                    placeholder="00000"
                  />
                  <small class="text-danger" v-if="errorCode">Неправильний код</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Увійти
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/services/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/services/auth/auth';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      code: '',
      phone: '',
      showLoginForm: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      errorPhone: false,
      errorCode: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    sendCode() {

      this.$refs.codeForm.validate()
        .then(async(success) => {
      
        if (success) {

          const codeResponse = await AuthService.code(this.phone)
          
          if (codeResponse.result) {
            this.showLoginForm = !this.showLoginForm
          } else {
            this.errorPhone = true
          }
        }
      })
    },
    sendLogin() {

      this.$refs.loginForm.validate()
        .then(async(success) => {
      
        if (success) {

          const loginResponse = await AuthService.login(this.phone, this.code)
          localStorage.setItem('sessionId', loginResponse.sessionId)

          if (loginResponse.result) {

            const profilesResponse = await AuthService.profiles()

            let businesses = profilesResponse.business

            localStorage.setItem('businesses', JSON.stringify(businesses))
            localStorage.setItem('business-id', businesses[0].profileId)

            let userData = {
              fullName: profilesResponse.personal[0].fullName,
              phone: this.phone,
              role: businesses[0].role,
              ability: [{
                  action: 'manage',
                  subject: 'all',
              }, ],
            }

            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)

            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Вітаємо, user`,
                    icon: 'CoffeeIcon',
                    variant: 'primary',
                    text: `Ви успішно ввійшли як ${userData.role}. Тепер ви можете почати працювати!`,
                  },
                })
              })

          } else {
            this.errorCode = true
          }
        }
      })

          // useJwt.login({
          //   phone: this.phone,
          //   // password: this.password,
          // })
          //   .then(response => {
          //     const { userData } = response.data
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(userData))
          //     this.$ability.update(userData.ability)

          //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: `Вітаємо, ${userData.fullName || userData.username}`,
          //             icon: 'CoffeeIcon',
          //             variant: 'primary',
          //             text: `Ви успішно ввійшли як ${userData.role}. Тепер ви можете почати працювати!`,
          //           },
          //         })
          //       })
          //   })
          //   .catch(error => {
          //     this.$refs.loginForm.setErrors(error.response.data.error)
          //   })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.dark-layout .brand-logo .logo-dark-path {
  fill: white;
}

</style>
