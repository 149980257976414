var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticClass:"logo-light",attrs:{"src":require("./logo.png"),"alt":"Logo","width":"150"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Вітаємо в petrolcard! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Увійдіть у свій обліковий запис і почніть пригоду ")]),(!_vm.showLoginForm)?_c('validation-observer',{ref:"codeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendCode($event)}}},[_c('b-form-group',{attrs:{"label":"Номер телефону","label-for":"login-phone"}},[_c('validation-provider',{attrs:{"name":"Номер телефону","vid":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-phone","state":errors.length > 0 ? false:null,"name":"login-phone","placeholder":"380681234567","pattern":"^\\+?3?8?(0[3-9][0-9]\\d{7})$","title":"Номер телефону повинен бути у форматі 380681234567","oninvalid":"setCustomValidity('Номер телефону повинен бути у форматі 380681234567')","oninput":"try{setCustomValidity('')}catch(e){}"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.errorPhone)?_c('small',{staticClass:"text-danger"},[_vm._v("Неправильний номер телефону")]):_vm._e()]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Надіслати код ")])],1)]}}],null,false,642372353)}):_vm._e(),(_vm.showLoginForm)?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendLogin($event)}}},[_c('b-form-group',{attrs:{"label":"Номер телефону","label-for":"login-phone"}},[_c('validation-provider',{attrs:{"name":"Номер телефону","vid":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-phone","state":errors.length > 0 ? false:null,"name":"login-phone","placeholder":"380681234567","pattern":"^\\+?3?8?(0[3-9][0-9]\\d{7})$","title":"Номер телефону повинен бути у форматі 380681234567","oninvalid":"setCustomValidity('Номер телефону повинен бути у форматі 380681234567')","oninput":"try{setCustomValidity('')}catch(e){}"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Код з смс","label-for":"login-code"}},[_c('validation-provider',{attrs:{"name":"Код з смс","vid":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-code","state":errors.length > 0 ? false:null,"name":"login-code","placeholder":"00000"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),(_vm.errorCode)?_c('small',{staticClass:"text-danger"},[_vm._v("Неправильний код")]):_vm._e()]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Увійти ")])],1)]}}],null,false,128507145)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }